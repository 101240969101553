import {Outlet} from "@remix-run/react";
import ResultXDarkLogo from "~/assets/resultx-logo-dark.webp";


export default function AuthLayout() {

    return (
        <div className="flex items-center min-h-screen">
            <div className="card w-96 bg-base-100 border border-base-300 h-full min-h-screen">
                <div className="card-body flex flex-col justify-center relative">
                    <div className="mb-auto flex justify-center">
                        <img slot="dark" src={ResultXDarkLogo} alt="" className="max-h-14"/>
                    </div>
                    <div className="mb-auto">
                        <Outlet/>
                    </div>
                </div>
            </div>
            <div className="signin-hero flex-grow h-full min-h-screen">
                &nbsp;
            </div>
        </div>
    )
}
